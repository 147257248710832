import React from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Typography, Box, Button, Paper, Grid } from '@mui/material';
import QRCode from 'qrcode.react';
import logo from '../../images/home1.png';

const ReceiptPage = () => {
    const location = useLocation();
    const {
        trackingCode, 
        senderName, 
        senderPhone, 
        receiverName, 
        receiverPhone, 
        recipientAddress, 
        parcelContent, 
        packageType,
        price, 
        itemsCount,
        source, 
        destination, 
        parcelWeight, 
        modeOfPayment, 
        fragile, 
        highValue, 
        perishable, 
        urgent, 
        valueInKsh 
    } = location.state || {};

    const username = localStorage.getItem('username');
    
    const handlePrint = () => {
        window.print();
    };

    // Get current date and time
    const currentDateTime = new Date();
    const formattedDate = currentDateTime.toLocaleDateString();
    const formattedTime = currentDateTime.toLocaleTimeString();

    const parcelWeightInKg = parcelWeight/1000;

    return (
        <Container maxWidth="sm">
            <style>
                {`
                @media print {
                    .no-print {
                        display: none;
                    }
                }
                `}
            </style>
            <Box 
                sx={{ 
                    mt: 2, 
                    textAlign: 'center', 
                    border: '1px solid #ddd',
                    borderRadius: 2, 
                    p: 0, 
                    margin: 2,
                    bgcolor: '#fff',
                    maxWidth: '100%',
                    width: '100%'
                }}
            >
                <Box sx={{ textAlign: 'center', mt: 2 }}>
                    <img src={logo} alt="Organization Logo" style={{ width: '150px', marginBottom: '16px' }} />
                </Box>
                <Typography variant="h6" component="h2" gutterBottom>
                Dream Courier Ltd
                </Typography>
                <Typography variant="h5" component="h1" gutterBottom>
                    Parcel Receipt
                </Typography>
                
                <Typography variant="body1" gutterBottom>
                    {source} <span style={{ margin: '0 8px' }}>→</span> {destination}
                </Typography>

                <Typography variant="h5" component="h3" gutterBottom>
                    Tracking No: {trackingCode}/{itemsCount}
                </Typography>

                <Grid container spacing={2} sx={{ mb: 2, justifyContent: 'center' }}>
                    <Grid item xs={6} sm={3}>
                        <Paper elevation={2} sx={{ p: 2, textAlign: 'center' }}>
                            <Typography variant="h6" gutterBottom>
                                Sender:
                            </Typography>
                            <Typography variant="body1">{senderName}</Typography>
                            <Typography variant="body1">{senderPhone}</Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <Paper elevation={1} sx={{ p: 2, textAlign: 'center' }}>
                            <Typography variant="h6" gutterBottom>
                                Recipient:
                            </Typography>
                            <Typography variant="body1">{receiverName}</Typography>
                            <Typography variant="body1">{receiverPhone}</Typography>
                        </Paper>
                    </Grid>
                </Grid>

                <Box sx={{ mb: 1, textAlign: 'center' }}>
                    <Typography variant="body1" gutterBottom>
                        Address: {recipientAddress}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        <span>{parcelContent}</span> <span>: Weight: {parcelWeightInKg.toFixed(2)} kg</span>
                    </Typography>

                    <Typography variant="body1" gutterBottom>
                        Packaging: {packageType}
                    </Typography>
                </Box>

                {/* Conditionally display the additional parcel properties */}
                {fragile && (
                    <Typography variant="body1" gutterBottom>
                        <strong>Fragile:</strong> Yes
                    </Typography>
                )}
                {perishable && (
                    <Typography variant="body1" gutterBottom>
                        <strong>Perishable:</strong> Yes
                    </Typography>
                )}
                {urgent && (
                    <Typography variant="body1" gutterBottom>
                        <strong>Urgent:</strong> Yes
                    </Typography>
                )}
                {highValue && (
                    <>
                        <Typography variant="body1" gutterBottom>
                            <strong>High Value:</strong> Yes
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            <strong>Value in Ksh:</strong> {valueInKsh}
                        </Typography>
                    </>
                )}

                <Box sx={{ mb: 2, textAlign: 'center' }}>
                    <Typography variant="body1" gutterBottom>
                        <span>Ksh{price}</span> <span>: {modeOfPayment}</span>
                    </Typography>
                </Box>

                <Box sx={{ textAlign: 'center', mt: 0, mb: 2 }}>
                    <QRCode value={trackingCode} size={70} />
                </Box>

                <Box sx={{ mt: 2, textAlign: 'center' }}>
                    <Typography variant="body1" gutterBottom>
                        Booked By: {username} at {formattedDate} {formattedTime}
                    </Typography>
                </Box>

                {/* Add class 'no-print' to hide button during print */}
                <Button 
                    onClick={handlePrint} 
                    variant="contained" 
                    color="primary" 
                    className="no-print"
                >
                    Print Receipt
                </Button>
            </Box>
        </Container>
    );
};

export default ReceiptPage;
