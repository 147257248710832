import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Visibility from '@mui/icons-material/Visibility';
import { Grid, Paper, Button, Typography, Box, TablePagination,
   Drawer, Table, TableBody, TableCell, TableHead, TableRow, Select, TableContainer,
    TextField, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, IconButton, Alert, FormControl, Modal, InputLabel } from '@mui/material';
import Menu from '@mui/material/Menu';
import './../../App.css'; 

// import { Dashboard, Person, Report, AccountCircle, DirectionsBus, Schedule, Receipt, LocalShipping, ListAlt, BarChart, Menu } from '@mui/icons-material';
import EventIcon from '@mui/icons-material/Event';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ReceiptIcon from '@mui/icons-material/Receipt';
import Description from '@mui/icons-material/Description';
import CheckCircle from '@mui/icons-material/CheckCircle';
import { ListAlt, Receipt, Schedule, DirectionsBus, Book as BookIcon } from '@mui/icons-material';
import HomePageBanner from '../../images/Home_page_banner.png';
import BusForm from './BusForm';
import ParcelBooking from './ParcelBooking';
import ParcelListDialog from './ParcelListDialog';
import ParcelCollection from './ParcelCollection';
import CloseIcon from '@mui/icons-material/Close'; // Close button icon
import CancelIcon from '@mui/icons-material/Cancel'; // Icon for cancel button
import VoucherIcon from '@mui/icons-material/CardGiftcard';
import axiosInstance from '../Admin/axiosInstance'; 

const Home = () => {
  const navigate = useNavigate();
  const [busDrawerOpen, setBusDrawerOpen] = useState(false);
  const [parcelDrawerOpen, setParcelDrawerOpen] = useState(false);
  const [bookingListOpen, setBookingListOpen] = useState(false);
  const [parcelListOpen, setParcelListOpen] = useState(false);
  const [bookingData, setBookingData] = useState([]);
  const [parcelData, setParcelData] = useState([]);
  const [ticketDetailsOpen, setTicketDetailsOpen] = useState(false);
  const [ticketNumber, setTicketNumber] = useState('');
  const [ticketDetails, setTicketDetails] = useState(null);
  const [travelDate, setTravelDate] = useState('');
  const [direction, setDirection] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogOpenDispach, setDialogDispatchOpen] = useState(false);
  const [paymentDialogOpen, setPaymentDialogOpen] = useState(false);
  const [parcelStatusUpdates, setParcelStatusUpdates] = useState(false);
  const [selectedParcelIds, setSelectedParcelIds] = useState([]); 
  const [parcelStatus, setParcelStatus] = useState('');
  const [bookingDialogOpen, setBookingDialogOpen] = useState(false);
  const [parcels, setParcels] = useState([]);
  const [statusChanges, setStatusChanges] = useState({});
  const [collectedByData, setCollectedByData] = useState(null);
  const [isCollectedModalOpen, setIsCollectedModalOpen] = useState(false);
  const [selectedDirection, setSelectedDirection] = useState('');
  const [error, setError] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  const [voucherData, setVoucherData] = useState([]);
  const [voucherDialogOpen, setVoucherDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const [bookingList, setBookingList] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const [form, setForm] = useState({
    modeOfPayment: '',
    mobileNumber: '',
    mpesaAmount: '',
    cashAmount: ''
  });

    const handleChangeStatus = (e) => {
      const { name, value } = e.target;
      setForm({ ...form, [name]: value });
    };
  
    const handleStatusUpdate = (reservationId) => {
      setSelectedBooking(reservationId);
      setPaymentDialogOpen(true);
    };
  
    const handlePaymentSubmit = async () => {
      try {
        const paymentData = {
          modeOfPayment: form.modeOfPayment,
          mobileNumber: form.mobileNumber,
          mpesaAmount: form.mpesaAmount,
          cashAmount: form.cashAmount
        };
  
        await axiosInstance.patch(`/reservation/update/${selectedBooking}`, {
          ...paymentData, // Send payment data with the booking update
          status: 'Booked',
          reserved: false,
        });
  
        setPaymentDialogOpen(false);
        // Update the local state if necessary
      } catch (error) {
        console.error('Error updating reservation:', error);
      }
    };

  const toggleDrawer = (open) => () => {
    setIsDrawerOpen(open);
  };

  const handleMenuOpen = (event, booking) => {
    setAnchorEl(event.currentTarget);
    setSelectedBooking(booking);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedBooking(null);
  };

  const toggleBusDrawer = (open) => () => {
    closeAll(); // Close all other sections
    setBusDrawerOpen(open);
  };

  const toggleParcelDrawer = (open) => () => {
    closeAll(); // Close all other sections
    setParcelDrawerOpen(open);
  };

  const handleBookingListClick = () => {
    setBookingDialogOpen(true); 
    setTicketDetailsOpen(false);
  };

  // Your custom styles
const customTableHeadStyle = {
  backgroundColor: '#1976d2',
  color: 'white',
  fontWeight: 'bold',
  fontSize: '14px',
};

const customPaginationStyle = {
  // Customize the pagination component styles
  '& .MuiTablePagination-select': {
    color: '#1976d2',
  },
  '& .MuiTablePagination-displayedRows': {
    color: '#1976d2',
  },
  '& .MuiTablePagination-actions .MuiIconButton-root': {
    color: '#1976d2',
  },
};

// Function to handle fetching collected by data
  const handleCollectedByClick = async (parcelId) => {
    try {
      const response = await axiosInstance.get(`/parcelreceiver/collectedBy/${parcelId}`);
      if (response.data) {
        setCollectedByData(response.data);
        setError(null);  // Clear any previous error
      } else {
        setCollectedByData(null);
        setError('The parcel has not yet been collected.');
      }
      setIsCollectedModalOpen(true);
    } catch (error) {
      setError('Error fetching collected by data');
      console.error(error);
    }
  };

  const handleCloseModal = () => {
    setIsCollectedModalOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const customButtonStyle = {
    marginLeft: 2,
  };

  const handleDialogSubmit = async () => {
    try {
      const directionCode = direction === 'Nairobi-Wajir' ? 1 : 2;
      const response = await axiosInstance.get(`/reservation/allondate/${travelDate}/${directionCode}`);
      setBookingData(response.data);
      setBookingListOpen(true); // Open the booking list
      setBookingDialogOpen(false); // Close the dialog
    } catch (error) {
      console.error('Error fetching booking data:', error);
    }
  };
  

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDialogDispatchClose = () => {
    setDialogDispatchOpen(false);
  };
  // Function to toggle the parcel drawer
  const toggleStatusDrawer = (open) => async () => {
    closeAll();
    setParcelStatusUpdates(open);

  };

  const handleDirectionSelect = (event) => {
    setSelectedDirection(event.target.value); // Set the selected direction
  };


  const handleParcelListClick = () => {
    setDialogOpen(true); // Open the dialog for date and direction selection
  };

  const handleDialogConfirm = async (startDate, endDate, direction) => {
    setDialogOpen(false);
    closeAll(); // Close all other sections
    try {
      const response = await axiosInstance.get(`/parcel/list/${startDate}/${endDate}/${direction}`);
      setParcelData(response.data);
      setParcelListOpen(true);
    } catch (error) {
      console.error('Error fetching parcel data:', error);
    }
  };

  // Fetch parcels after the direction is selected
const fetchParcelsForDispatch = async () => {
  try {
    const response = await axiosInstance.get(`/parcel/dispatch/${selectedDirection}`);
    setParcelData(response.data); // Populate the drawer with parcel data
    setDialogDispatchOpen(false); // Close the dialog after fetching the data
    setParcelStatusUpdates(true); // Open the parcel status drawer
  } catch (error) {
    console.error('Error fetching parcel data:', error);
  }
};

// Open the direction selection dialog
const handleOpenDirectionDialog = () => {
  setDialogDispatchOpen(true); // Open the dialog
};


  // Function to handle status change
  const handleStatusChange = (event) => {
    setParcelStatus(event.target.value);
  };

  // Function to handle parcel selection
  const handleParcelSelection = (parcelId) => {
    setSelectedParcelIds((prevSelectedIds) =>
      prevSelectedIds.includes(parcelId)
        ? prevSelectedIds.filter((id) => id !== parcelId)
        : [...prevSelectedIds, parcelId]
    );
  };

  const handleFetchParcels = async () => {
    try {
      const response = await axiosInstance.get('/parcel/report/DISPATCHED');
      setParcelData(response.data);
      setDrawerOpen(true);
    } catch (error) {
      console.error('Error fetching parcel data:', error);
      alert('An error occurred while fetching parcel data');
    }
  };

  const statusFlow = ["INPROGRESS", "DISPATCHED", "DELIVERED", "COLLECTED"]; 
  const statusFlow1 = ["DELIVERED"]; 
  
  // Map statuses to report endpoints
  const statusToEndpoint = {
    DISPATCHED: '/parcel/report/DISPATCHED',
    DELIVERED: '/parcel/report/DELIVERED',
    COLLECTED: '/parcel/report/COLLECTED'
  };
  
  // Function to update the selected parcel statuses
  // Function to update the selected parcel statuses
const handleParcelUpdateClick = async () => {
  if (selectedParcelIds.length > 0) {
    try {
      if (selectedParcelIds.length > 1) {
        // Update multiple parcels using the batch API
        const parcelStatusUpdates = selectedParcelIds.reduce((acc, id) => {
          acc[id] = parcelStatus; // Set the status for each selected parcel
          return acc;
        }, {});

        const response = await axiosInstance.patch('/parcel/status', {
          parcelStatusUpdates,
        });

        if (response.status === 200) {
          alert('Parcel statuses updated successfully');

          // Fetch parcels that match the new status
          if (statusToEndpoint[parcelStatus]) {
            const updatedParcels = await axiosInstance.get(statusToEndpoint[parcelStatus]);

            // Handle empty response
            if (updatedParcels.data && updatedParcels.data.length > 0) {
              setParcelData(updatedParcels.data);
            } else {
              alert('No parcels found with the updated status');
              setParcelData([]); // Optionally clear the table if no data is returned
            }
          } else {
            alert(`No endpoint found for the status ${parcelStatus}`);
          }
        } else {
          alert('Failed to update parcel statuses');
        }

      } else if (selectedParcelIds.length === 1) {
        // Update a single parcel using the single update API
        const parcelId = selectedParcelIds[0];
        const response = await axiosInstance.patch(`/parcel/${parcelId}`, {
          status: parcelStatus,
        });

        if (response.status === 200) {
          alert('Parcel status updated successfully');

          // Fetch parcels that match the new status
          if (statusToEndpoint[parcelStatus]) {
            const updatedParcels = await axiosInstance.get(statusToEndpoint[parcelStatus]);

            // Handle empty response
            if (updatedParcels.data && updatedParcels.data.length > 0) {
              setParcelData(updatedParcels.data);
            } else {
              alert('No parcels found with the updated status');
              setParcelData([]); // Optionally clear the table if no data is returned
            }
          } else {
            alert(`No endpoint found for the status ${parcelStatus}`);
          }
        } else {
          alert('Failed to update parcel status');
        }
      }

    } catch (error) {
      console.error('Error updating parcel statuses:', error);
      alert('An error occurred while updating parcel statuses');
    }
  } else {
    alert('No parcels selected for update');
  }
};
  
  const handleSelectParcel = (parcelId) => {
    setSelectedParcelIds(prevIds =>
      prevIds.includes(parcelId) ? prevIds.filter(id => id !== parcelId) : [...prevIds, parcelId]
    );
  };
  
  // single
  // Handle status change directly in the dropdown
  const handleChange = (parcelId, newStatus) => {
    setStatusChanges((prev) => ({
      ...prev,
      [parcelId]: newStatus,
    }));
  };

  const handleChangeReserved = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };
  

 
  // Update the status of a specific parcel
  const handleUpdateClick = async (parcelId) => {
    const newStatus = statusChanges[parcelId];
    const currentStatus = parcelData.find(parcel => parcel.id === parcelId).status;

    if (!newStatus) {
      alert('Please select a status before updating');
      return;
    }

    // Prevent updating to the same or previous status
    const currentStatusIndex = statusFlow.indexOf(currentStatus);
    const newStatusIndex = statusFlow.indexOf(newStatus);

    if (newStatusIndex <= currentStatusIndex) {
      alert('Cannot update parcel to the same or a previous status.');
      return;
    }

    try {
      const response = await axiosInstance.patch(`/parcel/${parcelId}`, {
        status: newStatus,
      });

      if (response.status === 200) {
        alert('Parcel status updated successfully');
        // Refetch the parcels to reflect the updated status
        const updatedParcels = await axiosInstance.get('/parcel/viewAll');
        setParcels(updatedParcels.data);
      } else {
        alert('Failed to update parcel status');
      }
    } catch (error) {
      console.error('Error updating parcel status:', error);
      alert('An error occurred while updating parcel status');
    }
  };

  const handleTicketDetailsClick = () => {
    closeAll();
    setBookingListOpen(false); // Close the booking list table
    setTicketDetailsOpen(true);
  };

  const handleTicketNumberChange = (event) => {
    setTicketNumber(event.target.value);
  };

  const fetchTicketDetails = async () => {
    try {
      const response = await axiosInstance.get(`/reservation/viewticket/${ticketNumber}`);
      setTicketDetails(response.data);
      setError(null); // Clear any previous error
      setTicketDetailsOpen(false); // Close the dialog after fetching
      return response.data; // Return the fetched ticket details
    } catch (error) {
      console.error('Error fetching ticket details:', error);
      if (error.response && error.response.status === 404) {
        setError('Ticket number not found. Please check the number and try again.');
      } else {
        setError('An error occurred while fetching ticket details. Please try again later.');
      }
      return null; // Return null if an error occurs
    }
  };
  
  
    const generateVoucherNo = () => {
      const randomString = Math.random().toString(36).substring(2, 7).toUpperCase();
      return `DCL${randomString}`;
    };

    const handleCancelWithoutVoucher = async () => {
      if (!selectedBooking || !selectedBooking.reservationId) {
        alert('No reservation ID found. Please check the booking details.');
        return;
      }
    
      const reservationId = selectedBooking.reservationId;
      
      try {
        const voucherNo = generateVoucherNo();
        const response = await axiosInstance.put(`/reservation/cancel/${reservationId}/${voucherNo}`, { method: 'PUT' });
        if (response.status === 200) {
          alert('Ticket canceled successfully without a voucher.');
        } else {
          alert('Failed to cancel the ticket.');
        }
      } catch (error) {
        console.error('Error canceling ticket:', error);
        alert('An error occurred while canceling the ticket.');
      }
      setOpenDialog(false); // Close the dialog
    };
  
    const handleCancelWithVoucher = async () => {
      if (!selectedBooking || !selectedBooking.reservationId) {
        alert('No reservation ID found. Please check the booking details.');
        return;
      }
    
      const reservationId = selectedBooking.reservationId;
      
      try {
        const voucherNo = generateVoucherNo();
        const response = await axiosInstance.put(`/reservation/cancel/${reservationId}/${voucherNo}`, { method: 'PUT' });
        if (response.status === 200) {
          // Calculate the expiry date
          const travelDate = new Date(response.data.travelDate);
          const expiryDate = new Date(travelDate);
          expiryDate.setDate(travelDate.getDate() + 60);
  
          // Include voucherNo and expiryDate in receiptDetails
          const receiptDetails = {
            ...response.data,
            voucherNo, // Include the generated voucher number
            expiryDate: expiryDate.toISOString().split('T')[0], // Format as YYYY-MM-DD
          };
          // Navigate to the voucher page with the receipt details
          navigate('/voucher', { state: receiptDetails });
          console.log(receiptDetails);
        } else {
          alert('Failed to cancel the ticket.');
        }
      } catch (error) {
        console.error('Error canceling ticket:', error);
        alert('An error occurred while canceling the ticket.');
      }
      setOpenDialog(false); // Close the dialog
    };
  
    const handleCancelTicketClick = (booking) => {
      setSelectedBooking(booking);
      setOpenDialog(true); // Open the dialog
    };
  
    const handleCancelDialogClose = () => {
      setOpenDialog(false); // Close the dialog
    };
  
    const cancelTicket = async () => {
      if (!ticketDetails || !ticketDetails.reservationId) {
        alert('No reservation ID found. Please check the ticket details.');
        return;
      }
    
      const reservationId = ticketDetails.reservationId;
    
      try {
        const voucherNo = generateVoucherNo();
        const response = await axiosInstance.put(`/reservation/cancel/${reservationId}/${voucherNo}`, {
          method: 'PUT',
        });
    
        if (response.status === 200) {
          alert('Ticket canceled successfully');
          // Calculate the expiry date
          const travelDate = new Date(response.data.travelDate);
          const expiryDate = new Date(travelDate);
          expiryDate.setDate(travelDate.getDate() + 60);

          // Include voucherNo and expiryDate in receiptDetails
          const receiptDetails = {
            ...response.data,
            voucherNo, // Include the generated voucher number
            expiryDate: expiryDate.toISOString().split('T')[0], // Format as YYYY-MM-DD
          };
          // Navigate to the voucher page with the receipt details
          navigate('/voucher', { state: receiptDetails });
          console.log(receiptDetails);
        } else {
          alert('Failed to cancel the ticket');
        }
      } catch (error) {
        console.error('Error canceling ticket:', error);
        alert('An error occurred while canceling the ticket.');
      }
    };

    const cancelTicketBook = async () => {
      if (!selectedBooking || !selectedBooking.reservationId) {
        alert('No reservation ID found. Please check the booking details.');
        return;
      }
    
      const reservationId = selectedBooking.reservationId;
    
      try {
        const voucherNo = generateVoucherNo();
        const response = await axiosInstance.put(`/reservation/cancel/${reservationId}/${voucherNo}`, {
          method: 'PUT',
        });
    
        if (response.status === 200) {
          alert('Ticket canceled successfully');
    
          // Check if the booking status is 'Reserved'
          if (selectedBooking.reserved) {
            // If reserved, do not generate voucher or navigate to voucher page
            console.log('Ticket canceled successfully, but no voucher generated.');
          } else {
            // Calculate the expiry date
            const travelDate = new Date(response.data.travelDate);
            const expiryDate = new Date(travelDate);
            expiryDate.setDate(travelDate.getDate() + 60);
    
            // Include voucherNo and expiryDate in receiptDetails
            const voucherNo = generateVoucherNo();
            const receiptDetails = {
              ...response.data,
              voucherNo, // Include the generated voucher number
              expiryDate: expiryDate.toISOString().split('T')[0], // Format as YYYY-MM-DD
            };
            // Navigate to the voucher page with the receipt details
            navigate('/voucher', { state: receiptDetails });
            console.log(receiptDetails);
          }
        } else {
          alert('Failed to cancel the ticket');
        }
      } catch (error) {
        console.error('Error canceling ticket:', error);
        alert('An error occurred while canceling the ticket.');
      }
    };
    


  const handleTransferSeat = async () => {
    try {
      console.log('Fetching ticket details...');
      const busDetails = await fetchTicketDetails();
      console.log('Ticket details:', busDetails); // Log to verify data
  
      if (busDetails) {
        navigate('/seat-transfer', {
          state: {
            reservationId: busDetails.reservationId,
            travelDate: busDetails.travelDate,
            direction: busDetails.direction,
            source: busDetails.source,
            destination: busDetails.destination,
            cost: busDetails.price,
            departure: busDetails.bookTime
          }
        });
      } else {
        console.error('No bus details received');
      }
    } catch (error) {
      console.error('Error handling seat transfer:', error);
    }
  };
  

  // voucher 
  const handleVoucherClick = async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await axiosInstance.get('/voucher/get');
      setVoucherData(response.data);
      setVoucherDialogOpen(true);
    } catch (error) {
      setError('Error fetching voucher data');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleVoucherActionClick = async (voucherNumber, direction) => {
    try {
      await axiosInstance.post(`/reservation/add/${voucherNumber}/1`);
      alert('Voucher successfully used!');
    } catch (error) {
      setError('Error using voucher');
      console.error(error);
    }
  };
  

  const updateTicket = async () => {
    try {
      const updatedDetails = {
        // Add the fields you want to update
        firstName: ticketDetails.firstName,
        lastName: ticketDetails.lastName,
        seatNumber: ticketDetails.seatNumber,
        // Add other fields as needed
      };

      const response = await axiosInstance.get(`/reservation/tickets/${ticketNumber}/update`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedDetails),
      });
 
      if (response.ok) {
        alert('Ticket updated successfully');
        fetchTicketDetails(); // Refresh ticket details
      } else {
        alert('Failed to update the ticket');
      }
    } catch (error) {
      console.error('Error updating ticket:', error);
    }
  };

 const handleReprintTicketClick = (index) => {
  if (!bookingData || bookingData.length === 0) {
    alert('No ticket details found. Please select a ticket.');
    return;
  }

  if (index < 0 || index >= bookingData.length) {
    alert('Invalid ticket index.');
    return;
  }

  const selectedTicket = bookingData[index];

  // Check if the ticket is canceled
  if (selectedTicket.status === 'CANCELLED') {
    alert('This ticket has been canceled and cannot be reprinted.');
    return;
  }

  // Proceed with reprinting logic
  console.log('Selected Ticket:', selectedTicket);

  // Navigate to the ticket page with the selected ticket details
  navigate('/ticket', { state: selectedTicket });
};



//   const handleReprintTicketClick = (index) => {
//     if (!bookingData || bookingData.length === 0) {
//         alert('No ticket details found. Please select a ticket.');
//         return;
//     }

//     if (index < 0 || index >= bookingData.length) {
//         alert('Invalid ticket index.');
//         return;
//     }

//     const selectedTicket = bookingData[index]; 
//     console.log('Selected Ticket:', selectedTicket); 

//     navigate('/ticket', { state: selectedTicket });
// };


const handleTransfer = (booking) => {
  if (booking) {
    if (booking.status === 'CANCELLED') {
      alert('This ticket has been canceled and cannot be transferred.');
      return;
    }

    console.log(`Transferring seat for booking: ${booking.reservationId}`);

    // Navigate to the seat transfer page with the booking details
    navigate('/seat-transfer', {
      state: {
        reservationId: booking.reservationId,
        travelDate: booking.bookDate,
        direction: booking.direction,
        source: booking.source,
        destination: booking.destination,
        cost: booking.price,
        departure: booking.departureTime, // Replace with the correct field if available
      }
    });

    handleMenuClose();
  } else {
    console.error('No booking details provided');
  }
};

  const closeAll = () => {
    setBusDrawerOpen(false);
    setParcelDrawerOpen(false);
    setParcelStatusUpdates(false);
    setBookingListOpen(false);
    setParcelListOpen(false);
    setTicketDetailsOpen(false);
    setTicketDetails(null);
    setParcelListOpen(false);
  };

  const handleManifestClick = () => {
    navigate('/manifest');
  };
  
  const handleFinancialClick = () => {
    navigate('/financial');
  };

  const handleClick = () => {
    navigate('/waybill');
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Grid container spacing={3}>
        {/* Content Section (70%) */}
        <Grid item xs={12} md={8}>
          {/* Tickets Section */}
          <Box sx={{ marginBottom: 3 }}>
            <Typography variant="h4" sx={{ display: 'flex', alignItems: 'center' }}>
              <EventIcon sx={{ marginRight: 1 }} /> Tickets
            </Typography>
            <Typography variant="subtitle1">Book & Manage Tickets</Typography>
            <Grid container spacing={2} sx={{ mt: 2 }}>
              <Grid item>
                <Button
                  variant="contained"
                  color="success"
                  startIcon={<BookIcon sx={{ fontSize: 30 }} />}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: 150, 
                    height: 60, 
                    minWidth: 150, 
                    minHeight: 60, 
                    justifyContent: 'center', 
                    textAlign: 'center', 
                    borderRadius: 2 
                  }}
                  onClick={toggleBusDrawer(true)}
                >
                  Book
                </Button>
              </Grid>
              <Grid item>
              <Button
                variant="outlined"
                startIcon={<Receipt sx={{ fontSize: 30 }} />}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  width: 150, 
                  height: 60, 
                  minWidth: 150, 
                  minHeight: 60, 
                  justifyContent: 'center', 
                  textAlign: 'center', 
                  borderRadius: 2 
                }}
                onClick={handleManifestClick} // Navigate to manifest page
              >
                Manifest
              </Button>
            </Grid>
              <Grid item>
              <Button
                variant="outlined"
                color='warning'
                startIcon={<Schedule sx={{ fontSize: 30 }} />}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  width: 150, 
                  height: 60, 
                  minWidth: 150, 
                  minHeight: 60, 
                  justifyContent: 'center', 
                  textAlign: 'center', 
                  borderRadius: 2 
                }}
                onClick={handleBookingListClick}
              >
                Booking List
              </Button>
            </Grid>

            {/* Booking Dialog */}
          <Dialog open={bookingDialogOpen} onClose={() => setBookingDialogOpen(false)}>
          <DialogTitle>Select Date and Route</DialogTitle>
              <DialogContent>
                <TextField
                  label="Travel Date"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  value={travelDate}
                  onChange={(e) => setTravelDate(e.target.value)}
                />
                <TextField
                  select
                  label="Route"
                  fullWidth
                  value={direction}
                  onChange={(e) => setDirection(e.target.value)}
                  sx={{ marginTop: 2 }} // Add some margin for spacing
                >
                  <MenuItem value="Nairobi-Wajir">Nairobi-Wajir</MenuItem>
                  <MenuItem value="Wajir-Nairobi">Wajir-Nairobi</MenuItem>
                </TextField>
              </DialogContent>
              <DialogActions>
                  <Button onClick={() => setBookingDialogOpen(false)}>Cancel</Button>
                  <Button onClick={handleDialogSubmit}>Submit</Button>
              </DialogActions>
              </Dialog>

              <Grid item>
                <Button
                  variant="outlined"
                  startIcon={<ListAlt sx={{ fontSize: 30 }} />}
                  // sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: 150, 
                    height: 60, 
                    minWidth: 150, 
                    minHeight: 60, 
                    justifyContent: 'center', 
                    textAlign: 'center', 
                    borderRadius: 2 
                  }}
                  onClick={handleTicketDetailsClick}
                >
                  Ticket Details
                </Button>
              </Grid>
              <Grid item>
        <Button
          variant="outlined"
          color="error"
          startIcon={<DirectionsBus sx={{ fontSize: 30 }} />}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: 150, 
            height: 60, 
            minWidth: 150, 
            minHeight: 60, 
            justifyContent: 'center', 
            textAlign: 'center', 
            borderRadius: 2 
          }}
          onClick={handleVoucherClick}
        >
          Voucher
        </Button>
      </Grid>

      <Dialog open={voucherDialogOpen} onClose={() => setVoucherDialogOpen(false)} maxWidth="md" fullWidth>
      <DialogTitle>Vouchers</DialogTitle>
      <DialogContent>
        {loading ? (
          <p>Loading...</p>
        ) : error ? (
          <p>{error}</p>
        ) : (
          <Box sx={{ mt: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={customTableHeadStyle}>Voucher Number</TableCell>
                  <TableCell sx={customTableHeadStyle}>Status</TableCell>
                  <TableCell sx={customTableHeadStyle}>Expiry Date</TableCell>
                  <TableCell sx={customTableHeadStyle}>First Name</TableCell>
                  <TableCell sx={customTableHeadStyle}>Last Name</TableCell>
                  <TableCell sx={customTableHeadStyle}>Phone</TableCell>
                  <TableCell sx={customTableHeadStyle}>Destination</TableCell>
                  <TableCell sx={customTableHeadStyle}>Source</TableCell>
                  <TableCell sx={customTableHeadStyle}>Price</TableCell>
                  <TableCell sx={customTableHeadStyle}>Used</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {voucherData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((voucher) => (
                  <TableRow key={voucher.voucherNumber}>
                    <TableCell>{voucher.voucherNumber}</TableCell>
                    <TableCell>{voucher.status}</TableCell>
                    <TableCell>{voucher.expiryDate}</TableCell>
                    <TableCell>{voucher.firstName}</TableCell>
                    <TableCell>{voucher.lastName}</TableCell>
                    <TableCell>{voucher.phone}</TableCell>
                    <TableCell>{voucher.destination}</TableCell>
                    <TableCell>{voucher.source}</TableCell>
                    <TableCell>{voucher.price}</TableCell>
                    <TableCell>{voucher.used ? "Yes" : "No"}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={voucherData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              sx={customPaginationStyle}
            />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setVoucherDialogOpen(false)} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
            </Grid>
          </Box>

          {/* Parcel Section */}
          <Paper sx={{ padding: 2, backgroundColor: '#f8d7da' }}>
            <Typography variant="h4" sx={{ display: 'flex', alignItems: 'center' }}>
              <LocalShippingIcon sx={{ marginRight: 1 }} /> Parcel
            </Typography>
            <Typography variant="subtitle1">Book & Manage Parcel</Typography>
            <Grid container spacing={2} sx={{ mt: 2 }}>
              <Grid item>
                <Button
                  variant="contained"
                  color="error"
                  startIcon={<LocalShippingIcon sx={{ fontSize: 30 }} />}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: 150, 
                    height: 60, 
                    minWidth: 150, 
                    minHeight: 60, 
                    justifyContent: 'center', 
                    textAlign: 'center', 
                    borderRadius: 2 
                  }}
                  onClick={toggleParcelDrawer(true)}
                >
                  Book
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color="error"
                  startIcon={<ListAlt sx={{ fontSize: 30 }} />}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: 150, 
                    height: 60, 
                    minWidth: 150, 
                    minHeight: 60, 
                    justifyContent: 'center', 
                    textAlign: 'center', 
                    borderRadius: 2 
                  }}
                  onClick={handleParcelListClick}
                >
                  List
                </Button>
              </Grid>
              <ParcelListDialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                onConfirm={handleDialogConfirm}
              />
              <Grid item>
                <Button
                  variant="outlined"
                  color="warning"
                  startIcon={<Schedule sx={{ fontSize: 30 }} />}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: 150, 
                    height: 60, 
                    minWidth: 150, 
                    minHeight: 60, 
                    justifyContent: 'center', 
                    textAlign: 'center', 
                    borderRadius: 2 
                  }}
                  onClick={handleOpenDirectionDialog}
                >
                  Dispatch
                </Button>
              </Grid>

              {/* Dialog for selecting direction */}
              <Dialog open={dialogOpenDispach} onClose={handleDialogDispatchClose}>
                <DialogTitle>Select Dispatch Route</DialogTitle>
                <DialogContent>
                  <Select
                    value={selectedDirection}
                    onChange={handleDirectionSelect}
                    fullWidth
                    displayEmpty
                  >
                    <MenuItem value="" disabled>Select Route</MenuItem>
                    <MenuItem value="1">Nairobi-Wajir</MenuItem>
                    <MenuItem value="2">Wajir-Nairobi</MenuItem>
                  </Select>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleDialogClose} color="secondary">
                    Cancel
                  </Button>
                  <Button onClick={fetchParcelsForDispatch} color="primary">
                    Confirm
                  </Button>
                </DialogActions>
              </Dialog>


              <Grid item>
              <Button
                variant="outlined"
                color="warning"
                startIcon={<Description sx={{ fontSize: 30 }} />}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  width: 150, 
                  height: 60, 
                  minWidth: 150, 
                  minHeight: 60, 
                  justifyContent: 'center', 
                  textAlign: 'center', 
                  borderRadius: 2 
                }}
                onClick={handleClick}
              >
                Waybill
              </Button>
            </Grid>


            <Grid item>
              <Button
                variant="outlined"
                color="warning"
                startIcon={<CheckCircle sx={{ fontSize: 30 }} />}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  width: 150, 
                  height: 60, 
                  minWidth: 150, 
                  minHeight: 60, 
                  justifyContent: 'center', 
                  textAlign: 'center', 
                  borderRadius: 2 
                }}
                onClick={handleFetchParcels}
              >
                Receive Parcel
              </Button>
            </Grid>


            <Drawer
              anchor="right"
              open={drawerOpen}
              onClose={() => setDrawerOpen(false)}
            >
              <div style={{ width: 600, padding: 20 }}>
                <Typography variant="h6">Parcel Details</Typography>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Select</TableCell>
                        <TableCell>ID</TableCell>
                        <TableCell>Source</TableCell>
                        <TableCell>Destination</TableCell>
                        <TableCell>Sender Name</TableCell>
                        <TableCell>Receiver Name</TableCell>
                        <TableCell>Description(Package, content, item count)</TableCell>
                        <TableCell>Status</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {parcelData.map(parcel => (
                        <TableRow key={parcel.id}>
                          <TableCell>
                            <input
                              type="checkbox"
                              checked={selectedParcelIds.includes(parcel.id)}
                              onChange={() => handleSelectParcel(parcel.id)}
                            />
                          </TableCell>
                          <TableCell>{parcel.id}</TableCell>
                          <TableCell>{parcel.source}</TableCell>
                          <TableCell>{parcel.destination}</TableCell>
                          <TableCell>{parcel.senderName}</TableCell>
                          <TableCell>{parcel.receiverName}</TableCell>
                          <TableCell>{`${parcel.packageType}, ${parcel.parcelContent}, ${parcel.itemsCount}`}</TableCell>
                          <TableCell>{parcel.status}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Select
                  value={parcelStatus}
                  onChange={(e) => setParcelStatus(e.target.value)}
                  fullWidth
                  sx={{ marginBottom: 2 }}
                >
                  {statusFlow1.map(status => (
                    <MenuItem key={status} value={status}>{status}</MenuItem>
                  ))}
                </Select>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleParcelUpdateClick}
                  sx={{ marginBottom: 2 }}
                >
                Receive Parcels
                </Button>
              </div>
            </Drawer>


            <Grid item>
              <Button
                variant="outlined"
                color="warning"
                startIcon={<Description sx={{ fontSize: 30 }} />}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  width: 150, 
                  height: 60, 
                  minWidth: 150, 
                  minHeight: 60, 
                  justifyContent: 'center', 
                  textAlign: 'center', 
                  borderRadius: 2 
                }}
                onClick={toggleDrawer(true)}
              >
                Collection
              </Button>
            </Grid>

            {/* Pass the drawer state to ParcelCollection */}
            <ParcelCollection isDrawerOpen={isDrawerOpen} toggleDrawer={toggleDrawer} />
          </Grid>
          </Paper>
        


        {/* Finance Section */}
        <Paper sx={{ padding: 2, backgroundColor: 'white' }}>
          <Typography variant="h4" sx={{ display: 'flex', alignItems: 'center' }}>
            <AccountBalanceIcon  sx={{ marginRight: 1 }} /> Finance
          </Typography>
          <Typography variant="subtitle1">Manage Finances</Typography>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item>
              <Button
                variant="outlined"
                color="warning"
                startIcon={<ReceiptIcon sx={{ fontSize: 30 }} />}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  width: 150, 
                  height: 60, 
                  minWidth: 150, 
                  minHeight: 60, 
                  justifyContent: 'center', 
                  textAlign: 'center', 
                  borderRadius: 2 
                }}
                onClick={handleFinancialClick} // Navigate to '/financial' on click
              >
                Financial
              </Button>
            </Grid>
          </Grid>
        </Paper>
       </Grid>


        {/* Image Section (30%) */}
        <Grid item xs={12} md={4}>
          <Paper sx={{ padding: 2, height: '100%' }}>
            <img
              src={HomePageBanner}
              alt="Custom Image"
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }}
            />
          </Paper>
        </Grid>
      </Grid>

      {/* Drawer for Bus Form */}
      <BusForm open={busDrawerOpen} onClose={toggleBusDrawer(false)} />

      {/* Drawer for Parcel Form */}
      <Drawer
        anchor="top"
        open={parcelDrawerOpen}
        onClose={toggleParcelDrawer(false)}
        sx={{
          '& .MuiDrawer-paper': {
            width: '50%',
            height: '95%',
            overflow: 'auto',
            background: 'rgba(255, 255, 255, 0.9)',
            backdropFilter: 'blur(10px)',
            boxShadow: 24,
            margin: 'auto',
            borderRadius: '10px',
            padding: '16px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          },
        }}
      >
        <ParcelBooking onClose={toggleParcelDrawer(false)} />
      </Drawer>

      {/* Booking List Table */}
      {bookingListOpen && (
        <Box sx={{ mt: 3 }}>
          <Typography variant="h5">Booking List</Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={customTableHeadStyle}>Name</TableCell>
                <TableCell sx={customTableHeadStyle}>ID Number</TableCell>
                <TableCell sx={customTableHeadStyle}>Phone</TableCell>
                <TableCell sx={customTableHeadStyle}>Destination</TableCell>
                <TableCell sx={customTableHeadStyle}>Source</TableCell>
                <TableCell sx={customTableHeadStyle}>Book Date</TableCell>
                <TableCell sx={customTableHeadStyle}>Seat Number</TableCell>
                <TableCell sx={customTableHeadStyle}>Price</TableCell>
                <TableCell sx={customTableHeadStyle}>Discount</TableCell>
                <TableCell sx={customTableHeadStyle}>Ref Number</TableCell>
                <TableCell sx={customTableHeadStyle}>Status</TableCell>
                <TableCell sx={customTableHeadStyle}>Mode of Payment</TableCell>
                <TableCell sx={customTableHeadStyle}>Booking Mode</TableCell>
                <TableCell sx={customTableHeadStyle}>Action</TableCell>
                <TableCell sx={customTableHeadStyle}>View</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {bookingData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((booking, index) => (
                <TableRow key={booking.reservationId}>
                  <TableCell>{`${booking.firstName} ${booking.lastName}`}</TableCell>
                  <TableCell>{booking.idNumber}</TableCell>
                  <TableCell>{booking.phone}</TableCell>
                  <TableCell>{booking.destination}</TableCell>
                  <TableCell>{booking.source}</TableCell>
                  <TableCell>{booking.bookDate}</TableCell>
                  <TableCell>{booking.seatNumber}</TableCell>
                  <TableCell>{booking.price}</TableCell>
                  <TableCell>{booking.discount ? booking.discount : '0'}</TableCell>
                  <TableCell>{booking.ticketNumber}</TableCell>
                  <TableCell>{booking.status ? booking.status : 'Active'}</TableCell>
                  <TableCell>{booking.modeOfPayment}</TableCell>
                  <TableCell>{booking.reserved ? "Reserved" : "Booked"}</TableCell>
                  <TableCell>
                    {booking.reserved && booking.status !== 'CANCELLED' ? (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleStatusUpdate(booking.reservationId)}
                      >
                        Mark as Booked
                      </Button>
                    ) : (
                      "Booked"
                    )}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      color="primary"
                      onClick={(e) => handleMenuOpen(e, booking)}
                    >
                      <Visibility />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl) && selectedBooking?.reservationId === booking.reservationId}
                      onClose={handleMenuClose}
                    >

                      <MenuItem onClick={() => handleTransfer(booking)}disabled={booking.status === 'CANCELLED'}>Transfer Seat</MenuItem>
                      <MenuItem onClick={() => handleCancelTicketClick(booking)}disabled={booking.status === 'CANCELLED'}>Cancel Ticket</MenuItem>
                      {/* <MenuItem onClick={() => cancelTicketBook(booking)}disabled={booking.status === 'CANCELLED'}>Cancel Ticket</MenuItem> */}
                      <MenuItem onClick={() => handleReprintTicketClick(index)}disabled={booking.status === 'CANCELLED'}>Reprint Ticket</MenuItem>
                    </Menu>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={bookingData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={customPaginationStyle}
          />
        </Box>
      )}

      {/* Cancel Ticket Dialog */}
      <Dialog open={openDialog} onClose={handleCancelDialogClose} maxWidth="sm" fullWidth>
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6" component="div">
            Cancel Ticket
          </Typography>
          <IconButton onClick={handleCancelDialogClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Box sx={{ py: 2 }}>
            <Typography variant="body1" gutterBottom>
              Do you want to cancel the reservation for{' '}
              <strong>
                {selectedBooking?.firstName} {selectedBooking?.lastName}
              </strong>?
            </Typography>
            <Typography variant="body2" color="textSecondary">
              This action cannot be undone.
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<CancelIcon />}
            onClick={() => handleCancelWithoutVoucher(selectedBooking?.reservationId)}
            sx={{ mr: 1 }}
          >
            Cancel without Voucher
          </Button>
          <Button
            variant="contained"
            color="success"
            startIcon={<VoucherIcon />}
            onClick={() => handleCancelWithVoucher(selectedBooking?.reservationId)}
            sx={{ mr: 1 }}
          >
            Cancel and Generate Voucher
          </Button>
          <Button variant="text" color="secondary" onClick={handleCancelDialogClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog for Payment Method */}
      <Dialog open={paymentDialogOpen} onClose={() => setPaymentDialogOpen(false)}>
        <DialogTitle>Select Mode of Payment</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth margin="normal">
                <InputLabel>Mode of Payment</InputLabel>
                <Select
                  name="modeOfPayment"
                  value={form.modeOfPayment}
                  onChange={ handleChangeStatus}
                  required
                >
                  <MenuItem value="Cash">Cash</MenuItem>
                  <MenuItem value="Mpesa">Mpesa</MenuItem>
                  <MenuItem value="Mcash">Mpesa and Cash</MenuItem>
                  <MenuItem value="Foc">FOC</MenuItem>
                  <MenuItem value="Voucher">Voucher</MenuItem>
                  <MenuItem value="AirtelMoney">Airtel Money</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* Conditional Fields */}
            {form.modeOfPayment === 'Mpesa' || form.modeOfPayment === 'AirtelMoney' ? (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Mobile Number"
                  name="mobileNumber"
                  value={form.mobileNumber}
                  onChange={handleChangeReserved}
                  required
                />
              </Grid>
            ) : null}

            {form.modeOfPayment === 'Mcash' && (
              <>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Mpesa Amount"
                    name="mpesaAmount"
                    value={form.mpesaAmount}
                    onChange={handleChangeReserved}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Mobile Number"
                  name="mobileNumber"
                  value={form.mobileNumber}
                  onChange={handleChangeReserved}
                  required
                />
                </Grid>
              </>
            )}

            {form.modeOfPayment === 'Foc' && (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Amount"
                  name="amount"
                  value="0" // FOC amount is always zero
                  disabled
                />
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>Cancel</Button>
          <Button onClick={handlePaymentSubmit} color="primary" variant="contained">
            Confirm Payment
          </Button>
        </DialogActions>
      </Dialog>

      {/* // Drawer for selecting parcels and updating status */}
      <Drawer anchor="right" open={parcelStatusUpdates} onClose={toggleStatusDrawer(false)}>
        <Box sx={{ width: 600, padding: 2 }}>
          <Typography variant="h6" gutterBottom>
            Select Parcels for Dispatch
          </Typography>

          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Select</TableCell>
                <TableCell>Ref No</TableCell>
                <TableCell>From</TableCell>
                <TableCell>To</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {parcelData.map((parcel) => (
                <TableRow key={parcel.id}>
                  <TableCell padding="checkbox">
                    <input
                      type="checkbox"
                      checked={selectedParcelIds.includes(parcel.id)}
                      onChange={() => handleParcelSelection(parcel.id)}
                    />
                  </TableCell>
                  <TableCell>{parcel.trackingCode}</TableCell>
                  <TableCell>{parcel.source}</TableCell>
                  <TableCell>{parcel.destination}</TableCell>
                  <TableCell>{parcel.status === 'INPROGRESS' ? 'RECEIVED' : parcel.status}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <Select
            value={parcelStatus}
            onChange={handleStatusChange}
            displayEmpty
            fullWidth
            sx={{ marginTop: 2 }}
          >
            <MenuItem value="" disabled>Select Status</MenuItem>
            {statusFlow.map((status) => (
              <MenuItem
                key={status}
                value={status}
                disabled={statusFlow.indexOf(status) < statusFlow.indexOf(parcelStatus)}
              >
                {status === "INPROGRESS" && "Received"}
                {status === "DISPATCHED" && "Dispatched"}
                {status === "DELIVERED" && "Arrived"}
                {status === "COLLECTED" && "Collected"}
              </MenuItem>
            ))}
          </Select>
    
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleParcelUpdateClick(selectedParcelIds[0])} // Assuming you're updating one parcel at a time
            sx={{ marginTop: 2 }}
          >
            Update Status
          </Button>
    
          {/* Error message display */}
          {error && <Alert severity="error">{error}</Alert>}
        </Box>
      </Drawer>

     {/* parcel TABLE */}
      {parcelListOpen && (
        <Box sx={{ mt: 3 }}>
          <Typography variant="h5">Parcel List</Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={customTableHeadStyle}>Sender</TableCell>
                <TableCell sx={customTableHeadStyle}>Receiver</TableCell>
                <TableCell sx={customTableHeadStyle}>Origin</TableCell>
                <TableCell sx={customTableHeadStyle}>Destination</TableCell>
                <TableCell sx={customTableHeadStyle}>Received Date</TableCell>
                <TableCell sx={customTableHeadStyle}>Price</TableCell>
                <TableCell sx={customTableHeadStyle}>Parcel Weight</TableCell>
                <TableCell sx={customTableHeadStyle}>Tracking Code</TableCell>
                <TableCell sx={customTableHeadStyle}>Items No</TableCell>
                <TableCell sx={customTableHeadStyle}>Status</TableCell>
                <TableCell sx={customTableHeadStyle}>Action</TableCell>
                <TableCell sx={customTableHeadStyle}>Collected By</TableCell> {/* New column */}
              </TableRow>
            </TableHead>
            <TableBody>
              {parcelData.map((parcel) => (
                <TableRow key={parcel.id}>
                  <TableCell>{parcel.senderName}</TableCell>
                  <TableCell>{parcel.receiverName}</TableCell>
                  <TableCell>{parcel.source}</TableCell>
                  <TableCell>{parcel.destination}</TableCell>
                  <TableCell>{parcel.bookDate}</TableCell>
                  <TableCell>{parcel.price}</TableCell>
                  <TableCell>{parcel.parcelWeight}</TableCell>
                  <TableCell>{parcel.trackingCode}</TableCell>
                  <TableCell>{parcel.itemsCount}</TableCell>
                  <TableCell>{parcel.status === 'INPROGRESS' ? 'RECEIVED' : parcel.status}</TableCell>
                  <TableCell>
                    <Select
                      value={statusChanges[parcel.id] || parcel.status}
                      onChange={(e) => handleChange(parcel.id, e.target.value)}
                      displayEmpty
                    >
                      <MenuItem value="" disabled>Select Status</MenuItem>
                      <MenuItem value="INPROGRESS">Received</MenuItem>
                      <MenuItem value="DISPATCHED">Dispatched</MenuItem>
                      <MenuItem value="DELIVERED">Arrived</MenuItem>
                      <MenuItem value="COLLECTED">Collected</MenuItem>
                    </Select>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleUpdateClick(parcel.id)}
                      sx={customButtonStyle}
                    >
                      Update
                    </Button>
                  </TableCell>
                  <TableCell>
                  <Typography 
                    onClick={() => handleCollectedByClick(parcel.id)} 
                    sx={{ color: 'blue', cursor: 'pointer', textDecoration: 'underline' }}
                  >
                    Collected By
                  </Typography>
                </TableCell>

                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={parcelData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />

          {/* Collected By Modal */}
          <Modal
            open={isCollectedModalOpen}
            onClose={handleCloseModal}
            aria-labelledby="collected-by-modal-title"
            aria-describedby="collected-by-modal-description"
          >
            <Box 
              sx={{ 
                padding: 4, 
                backgroundColor: 'white', 
                margin: 'auto', 
                maxWidth: '600px', 
                borderRadius: '10px', 
                boxShadow: 24,
                border: '1px solid #ddd'
              }}
            >
              {error ? (
                <Alert severity="error">{error}</Alert>
              ) : (
                collectedByData && (
                  <>
                    <Typography id="collected-by-modal-title" variant="h6" sx={{ marginBottom: 2 }}>
                      Parcel Collection Details
                    </Typography>

                    <Grid container spacing={2}>
                      {/* Parcel Details */}
                      <Grid item xs={12}>
                        <Typography variant="h6" sx={{ marginTop: 2 }}>Parcel Details</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography fontWeight="bold">Tracking Number:</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography>{collectedByData.parcel.trackingCode}</Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography fontWeight="bold">Origin:</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography>{collectedByData.parcel.source}</Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography fontWeight="bold">Destination:</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography>{collectedByData.parcel.destination}</Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography fontWeight="bold">Sender Name:</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography>{collectedByData.parcel.senderName}</Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography fontWeight="bold">Item Count:</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography>{collectedByData.parcel.itemsCount}</Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography fontWeight="bold">Parcel Content:</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography>{collectedByData.parcel.parcelContent}</Typography>
                      </Grid>

                      <Grid item xs={12}>
                        <Typography variant="h6" sx={{ marginTop: 2 }}>User collected Details</Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography fontWeight="bold">Name:</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography>{collectedByData.firstName}</Typography>
                      </Grid>

                      {/* <Grid item xs={6}>
                        <Typography fontWeight="bold">Last Name:</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography>{collectedByData.lastName || 'N/A'}</Typography>
                      </Grid> */}

                      <Grid item xs={6}>
                        <Typography fontWeight="bold">Phone Number:</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography>{collectedByData.phoneNumber}</Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography fontWeight="bold">ID Number:</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography>{collectedByData.idNumber}</Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography fontWeight="bold">Date:</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography>{collectedByData.date}</Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography fontWeight="bold">Time:</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography>{collectedByData.time}</Typography>
                      </Grid>
                    </Grid>
                  </>
                )
              )}
            </Box>
          </Modal>

        </Box>
      )}

      {/* Dialog for Ticket Number Input */}
      <Dialog open={ticketDetailsOpen} onClose={() => setTicketDetailsOpen(false)}>
        <DialogTitle>Enter Ticket Number</DialogTitle>
        <DialogContent>
          <TextField
            label="Ticket Number"
            value={ticketNumber}
            onChange={handleTicketNumberChange}
            fullWidth
          />
          {error && (
            <Alert severity="error" sx={{ marginTop: 2 }}>
              {error}
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setTicketDetailsOpen(false)}>Cancel</Button>
          <Button onClick={fetchTicketDetails} color="primary">Submit</Button>
        </DialogActions>
      </Dialog>

       {/* Centered Box for Displaying Ticket Details */}
      {ticketDetails && (
        <Box
          sx={{
            marginTop: 4,
            padding: 2,
            border: '1px solid #1976d2',
            borderRadius: '8px',
            backgroundColor: '#e3f2fd',
            maxWidth: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
            textAlign: 'center',
          }}
        >
          <Typography variant="h6" sx={{ marginBottom: 2 }}>Ticket Details</Typography>
          <Table size="small" sx={{ margin: 'auto' }}>
            <TableBody>
              <TableRow>
                <TableCell sx={{ padding: '4px', fontSize: '0.875rem' }}>Ticket Number</TableCell>
                <TableCell sx={{ padding: '4px', fontSize: '0.875rem' }}>{ticketDetails.ticketNumber}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ padding: '4px', fontSize: '0.875rem' }}>Passenger Name</TableCell>
                <TableCell sx={{ padding: '4px', fontSize: '0.875rem' }}>{ticketDetails.firstName} {ticketDetails.lastName}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ padding: '4px', fontSize: '0.875rem' }}>ID Number</TableCell>
                <TableCell sx={{ padding: '4px', fontSize: '0.875rem' }}>{ticketDetails.idNumber}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ padding: '4px', fontSize: '0.875rem' }}>Phone</TableCell>
                <TableCell sx={{ padding: '4px', fontSize: '0.875rem' }}>{ticketDetails.phone}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ padding: '4px', fontSize: '0.875rem' }}>Origin</TableCell>
                <TableCell sx={{ padding: '4px', fontSize: '0.875rem' }}>{ticketDetails.source}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ padding: '4px', fontSize: '0.875rem' }}>Destination</TableCell>
                <TableCell sx={{ padding: '4px', fontSize: '0.875rem' }}>{ticketDetails.destination}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ padding: '4px', fontSize: '0.875rem' }}>Book Date</TableCell>
                <TableCell sx={{ padding: '4px', fontSize: '0.875rem' }}>{ticketDetails.bookDate}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ padding: '4px', fontSize: '0.875rem' }}>Seat Number</TableCell>
                <TableCell sx={{ padding: '4px', fontSize: '0.875rem' }}>{ticketDetails.seatNumber}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ padding: '4px', fontSize: '0.875rem' }}>Amount</TableCell>
                <TableCell sx={{ padding: '4px', fontSize: '0.875rem' }}>{ticketDetails.price}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ padding: '4px', fontSize: '0.875rem' }}>Discount</TableCell>
                <TableCell sx={{ padding: '4px', fontSize: '0.875rem' }}>
                  {ticketDetails.discount ? ticketDetails.discount : '0'}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ padding: '4px', fontSize: '0.875rem' }}>Travel Date</TableCell>
                <TableCell sx={{ padding: '4px', fontSize: '0.875rem' }}>{ticketDetails.travelDate}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ padding: '4px', fontSize: '0.875rem' }}>Pickup Location</TableCell>
                <TableCell sx={{ padding: '4px', fontSize: '0.875rem' }}>{ticketDetails.pickUpLocation}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ padding: '4px', fontSize: '0.875rem' }}>Book Status</TableCell>
                <TableCell sx={{ padding: '4px', fontSize: '0.875rem' }}>
                  {ticketDetails.reserved ? 'Reserved' : 'Booked'}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>

          <Box sx={{ marginTop: 2 }}>
            <Button variant="contained" color="primary" onClick={handleTransferSeat} sx={{ marginRight: 2 }}>Transfer Seat</Button>
            {/* <Button variant="contained" color="primary" onClick={updateTicket} sx={{ marginRight: 2 }}>Transfer Seat</Button> */}
            <Button variant="contained" color="secondary" onClick={cancelTicket}>Cancel Ticket</Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Home;
