import React from 'react';
import { Box, Typography, Button, Paper } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import QRCode from 'react-qr-code';
import logo from '../images/home1.png';

const TicketPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const username = localStorage.getItem('username');

  const ticket = location.state || {};

  const printTicket = () => {
    window.print();
  };

  // Get current date and time
  const currentDate = new Date();
  const formattedDate = `${currentDate.toLocaleDateString()} ${currentDate.toLocaleTimeString()}`;

  return (
    <Box sx={{ padding: 2, backgroundColor: '#f0f0f0', minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Paper sx={{ padding: 2, width: 300, maxWidth: '100%', borderRadius: 2, border: '1px solid #ddd', boxShadow: '0 0 10px rgba(0,0,0,0.1)' }}>
        <Box sx={{ textAlign: 'center', mb: 2 }}>
          <Box sx={{ textAlign: 'center', mt: 2 }}>
            <img src={logo} alt="Organization Logo" style={{ width: '150px', marginBottom: '16px' }} />
          </Box>
          <Typography variant="h6" gutterBottom>
            Dream C
          </Typography>
        </Box>
        <Typography variant="body1" align="center" className="title" gutterBottom>
          {ticket.source} &rarr; {ticket.destination}
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', border: '1px solid #ddd', borderRadius: 2, padding: 2 }} id="ticket-content">
          <Typography variant="h5" className="title" gutterBottom>
            {ticket.ticketNumber}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {`${ticket.firstName} ${ticket.lastName}`}
          </Typography>
          <Typography variant="body1" gutterBottom>
            Seat Number: {ticket.seatNumber}
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mb: 1 }}>
            <Typography variant="body1">ID: {ticket.idNumber}</Typography>
            <Typography variant="body1">Phone: {ticket.phone}</Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mb: 1 }}>
            <Typography variant="body1">Mode of Payment: {ticket.modeOfPayment}</Typography>
            <Typography variant="body1">
              Price: Ksh {ticket.price} <br />
              Discount: Ksh {ticket.discount}
            </Typography>
          </Box>
          <Typography variant="body1" className="header" gutterBottom>
            Departure
          </Typography>
          <Typography variant="body1" gutterBottom>
            {`${ticket.travelDate} ${ticket.scheduleTime || '7:00 pm'}`}
          </Typography>

          {/* New Fields */}
          <Box sx={{ width: '100%', mt: 2 }}>
            <Typography variant="body1" className="header" gutterBottom>
              Has Luggage: {ticket.hasLuggage ? 'Yes' : 'No'}
            </Typography>
            {ticket.hasLuggage && (
              <>
                <Typography variant="body1" gutterBottom>
                  Luggage Description: {ticket.luggageDescription || 'N/A'}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Amount for Luggage: Ksh {ticket.luggageAmount || '0'}
                </Typography>
              </>
            )}
            <Typography variant="body1" className="header" gutterBottom>
              Gender: {ticket.gender || 'N/A'}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>

          <QRCode 
            value={`https://dreamcourierltd.co.ke/ticket-details/${ticket.ticketNumber}`} 
            size={100} 
          />
          {/* <QRCode 
            value={`https://dreamcourierltd.co.ke/ticket-details/${ticket.ticketNumber}`} 
            size={100} 
          /> */}
          </Box>
        </Box>

        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between', fontSize: '0.875rem' }}>
          <Typography variant="body1">
            Booked By: {username} On: {formattedDate}
          </Typography>
        </Box>

        {/* Footer with "Powered by Bravo" */}
        <Box sx={{ mt: 2, textAlign: 'center' }}>
          <Typography variant="body2" color="textSecondary">
            Powered by Bravo
          </Typography>
        </Box>

        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
          <Button variant="contained" color="primary" className="no-print" onClick={printTicket}>
            Print
          </Button>
        </Box>
      </Paper>

      <style>
        {`
          @media print {
            .no-print {
              display: none;
            }

            #ticket-content {
              border: none;
              box-shadow: none;
              width: 100%;
              padding: 0;
              margin: 0;
              page-break-inside: avoid;
            }

            body {
              margin: 0;
              padding: 0;
              width: 100mm; /* A4 size */
              height: 297mm; /* A4 size */
            }

            @page {
              size: A4;
              margin: 10mm;
            }

            .qr-code {
              display: block;
              margin: 0 auto;
            }
          }
        `}
      </style>
    </Box>
  );
};

export default TicketPage;
