import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, Grid, TextField, MenuItem, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import axiosInstance from '../Admin/axiosInstance';
import jsPDF from 'jspdf';
import DataTable from 'react-data-table-component';
import logoImg from '../../images/home1.png';
import './../../App.css'; 
// import AssignBusModal from './AssignBusModal';

const UserManifest = () => {
  const [schedules, setSchedules] = useState([]);
  const [bookedSeats, setBookedSeats] = useState([]);
  const [selectedBus, setSelectedBus] = useState('');
  const [selectedDriver, setSelectedDriver] = useState('');
  const [selectedConductor, setSelectedConductor] = useState('');
  const [buses, setBuses] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [conductors, setConductors] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  // const [modalOpen, setModalOpen] = useState(false);

  const [selectedDate, setSelectedDate] = useState('');
  const [selectedDirection, setSelectedDirection] = useState('');
  const [error, setError] = useState('');
  const [selectedLocation, setSelectedLocation] = useState(''); 

  // Array of locations
  const locations = [
    'Nairobi', 'Thika', 'Matuu', 'Kathiyoko', 'Mwingi', 'Ukasi', 'Bisan hargeysa',
    'Katumba', 'Bangal', 'Tula', 'Madogo', 'Garissa', 'Shimbirey', 'Dujis',
    'Madogashe', 'Habaswein', 'Qanchara', 'Lag boqol', 'Laheley', 'Wajir'
  ];

  const [dialogData, setDialogData] = useState({
    bus: '',
    driver: '',
    conductor: '',
    direction: ''
  });

  useEffect(() => {
    fetchSchedules();
    fetchBuses();
    fetchDrivers();
    fetchConductors();
  }, []);

  const fetchSchedules = async () => {
    try {
      const response = await axiosInstance.get('/schedule/viewallschedules');
      if (response.data.length > 0) {
        setSchedules(response.data);
        setError('');
      } else {
        setError('No schedules available.');
      }
    } catch (error) {
      setError('Error fetching schedules.');
      console.error('Error fetching schedules:', error);
    }
  };

  const fetchBuses = async () => {
    try {
      const response = await axiosInstance.get('/bus/viewAll');
      setBuses(response.data);
    } catch (error) {
      console.error('Error fetching buses:', error);
    }
  };

  const fetchDrivers = async () => {
    try {
      const response = await axiosInstance.get('/users/drivers');
      setDrivers(response.data);
    } catch (error) {
      console.error('Error fetching drivers:', error);
    }
  };

  const fetchConductors = async () => {
    try {
      const response = await axiosInstance.get('/users/conductors');
      setConductors(response.data);
    } catch (error) {
      console.error('Error fetching conductors:', error);
    }
  };

  const fetchBookedSeats = async () => {
    if (!selectedDate || !selectedDirection) {
      setError('Please select both date and route.');
      return;
    }

    try {
      const response = await axiosInstance.get(`/reservation/manifest/${selectedDate}/${selectedDirection}`);
      if (response.data.length > 0) {
        setBookedSeats(response.data);
        setSelectedBus(selectedDirection); 
        setError('');
      } else {
        setBookedSeats([]);
        setError('No available tickets for the selected date and route.');
      }
    } catch (error) {
      setError('Error fetching tickets.');
      console.error('Error fetching tickets:', error);
    }
  };

  // Function to handle check-in status change
  const handleCheckinStatusChange = async (reservationId, currentStatus) => {
    const newStatus = currentStatus === 'Checked In' ? 'Yet To Check In' : 'Checked In'; // Toggle status
    try {
      await axiosInstance.put(`/reservation/checkinstatus/${reservationId}/${newStatus}`);
      // Update the local state to reflect the new status
      setBookedSeats(prevSeats =>
        prevSeats.map(seat =>
          seat.reservationId === reservationId ? { ...seat, checkinStatus: newStatus } : seat
        )
      );
    } catch (error) {
      console.error('Error updating check-in status:', error);
    }
  };

  const handleAssignBus = async () => {
    try {
      await axiosInstance.post('/schedule/add', {
        bus: selectedBus,
        driver: selectedDriver,
        conductor: selectedConductor,
        location: selectedLocation,
      });

      // Store data locally for PDF
      setDialogData({
        bus: selectedBus,
        driver: selectedDriver,
        conductor: selectedConductor,
        location: selectedLocation,
        // route: selectedDirection
      });

      // Close the dialog
      closeAssignDialog();
    } catch (error) {
      console.error('Error assigning bus:', error);
    }
  };

  const downloadPDF = () => {
    const currentDate = new Date(); // Get the current date
    const formattedDate = currentDate.toISOString().split('T')[0];
    const departureTime = '19:00:00'; 
    const doc = new jsPDF({ orientation: 'portrait', unit: 'mm', format: 'a4' });
    const selectedSchedule = schedules.find(schedule => schedule.scheduleId === selectedBus);

    const logoWidth = 50;
    const pageWidth = doc.internal.pageSize.getWidth();
    const centerX = (pageWidth - logoWidth) / 2;

    // Add logo image
    doc.addImage(logoImg, 'PNG', centerX, 10, 50, 20);

    // Title and organization name centrally below the image
    doc.setFontSize(12);
    doc.text('DreamCruiser Ltd', centerX + (logoWidth / 2), 30, { align: 'center' });
    doc.setFontSize(11);
    doc.text(`Origin: ${selectedLocation}`, centerX + (logoWidth / 2), 35, { align: 'center' });

    // Vehicle Registration, Driver, and Conductor on the left side
    doc.setFontSize(10);
    doc.text(`Vehicle Registration: ${selectedBus}`, 10, 45);
    doc.text(`Driver: ${selectedDriver}`, 10, 50);
    doc.text(`Conductor: ${selectedConductor}`, 10, 55);

    // Departure Date & Time and Origin on the right side
    doc.text(`Departure Date & Time: ${formattedDate} ${departureTime}`, pageWidth - 40, 45, { align: 'right' });
    // doc.text(`Origin: ${selectedLocation}`, pageWidth - 40, 55, { align: 'right' });

    // Signature fields right-aligned below Departure Date & Time and Origin
    const signatureY = 50; // Adjust this value if necessary
    doc.text('Driver Signature: ________________________', pageWidth - 35, signatureY, { align: 'right' });
    doc.text('Conductor Signature: ________________________', pageWidth - 28, signatureY + 5, { align: 'right' });

    let y = 60; // Adjusted to leave space for the signatures
      doc.setFontSize(8);

      // Table headers, remove Source, Price, Paid Status
      doc.text('No.', 5, y);
      doc.text('Customer Name', 15, y);
      doc.text('ID Number', 45, y);
      doc.text('Destination', 70, y);
      doc.text('Pickup Station', 100, y); // Moved Pickup Station here
      doc.text('Seat Number', 125, y);
      doc.text('Nationality', 150, y);
      doc.text('Ticket Number', 170, y);
      y += 3;
      doc.line(3, y, 200, y); // Adjusted line length after content removal
      y += 3;

      // Data rows
      bookedSeats.forEach((seat, index) => {
          doc.text(String(index + 1), 5, y); // List number
          doc.text(`${seat.firstName || ''} ${seat.lastName || ''}`, 15, y);
          doc.text(String(seat.idNumber || ''), 45, y);
          doc.text(String(seat.destination || ''), 70, y);
          doc.text(String(seat.pickUpLocation || ''), 100, y); // Pickup Station
          doc.text(String(seat.seatNumber || ''), 125, y);
          doc.text(String(seat.nationality || ''), 150, y);
          doc.text(String(seat.ticketNumber || ''), 170, y);
          y += 3;
          doc.line(3, y, 200, y); // Line after each entry, adjusted for content
          y += 3;
      });

      // // Add signature fields
      // doc.text('Driver Signature: ________________________', 10, y + 10);
      // doc.text('Conductor Signature: ________________________', 10, y + 20);

      // Save the PDF
      doc.save('Bus_Booking_Details.pdf');
};

  const columns = [
    { name: 'First Name', selector: row => row.firstName, sortable: true },
    { name: 'Last Name', selector: row => row.lastName, sortable: true },
    { name: 'ID Number', selector: row => row.idNumber, sortable: true },
    { name: 'Origin', selector: row => row.source, sortable: true },
    { name: 'Destination', selector: row => row.destination, sortable: true },
    { name: 'Pickup Station', selector: row => row.pickUpLocation, sortable: true },
    { name: 'Price', selector: row => row.price, sortable: true },
    { name: 'Seat Number', selector: row => row.seatNumber, sortable: true },
    { name: 'Nationality', selector: row => row.nationality, sortable: true },
    // { name: 'Check-in Status', selector: row => row.checkinStatus, sortable: true },
    { name: 'Paid Status', selector: row => (row.modeOfPayment ? 'Paid' : 'Not Paid'), sortable: true },
    { name: 'Ticket Number', selector: row => row.ticketNumber, sortable: true },
    { name: 'Check-in Status', 
      selector: row => (
        <Button 
          variant="contained" 
          onClick={() => handleCheckinStatusChange(row.reservationId, row.checkinStatus)}
          style={{ backgroundColor: row.checkinStatus === 'Checked In' ? 'green' : 'grey' }}
        >
          {row.checkinStatus || 'Yet To Check In'}
        </Button>
      ), 
      sortable: true
    },
    {
      name: 'Status',
      selector: row => row.status ? row.status : 'Active',  // If null, set 'Active'
      sortable: true,
      cell: row => (
        <span style={{
          color: row.status === 'CANCELLED' ? 'red' : 'green'  // Red for Cancelled, Green for Active
        }}>
          {row.status ? row.status : 'Active'}
        </span>
      )
    }
  ];

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: '#1976d2',
        color: 'white',
        fontWeight: 'bold',
        fontSize: '14px',
      },
    },
    rows: {
      style: {
        minHeight: '40px', 
      },
    },
    cells: {
      style: {
        paddingLeft: '8px', // override the cell padding for data cells
        paddingRight: '8px',
      },
    },
  };

  const openAssignDialog = () => setOpenDialog(true);
  const closeAssignDialog = () => setOpenDialog(false);

  return (
    <Box sx={{ padding: 2 }}>
      <Typography 
        variant="h4" 
        gutterBottom 
        sx={{ 
          textAlign: 'center', 
          color: '#1976d2', 
          fontWeight: 'bold', 
          marginBottom: 3 
        }}
      >
        Bus Booking Details
      </Typography>

      <Box 
        sx={{ 
          marginBottom: 4, 
          padding: 3, 
          backgroundColor: '#f5f5f5', 
          borderRadius: '8px',
          boxShadow: '0 3px 6px rgba(0,0,0,0.1)'
        }}
      >
       <Button variant="outlined" color="primary" onClick={openAssignDialog}>
        Assign Bus
      </Button>

      <Dialog open={openDialog} onClose={closeAssignDialog}>
        <DialogTitle>Assign Bus, Driver, and Conductor</DialogTitle>
        <DialogContent>
          <TextField
            select
            label="Select Bus"
            fullWidth
            value={selectedBus}
            onChange={(e) => setSelectedBus(e.target.value)}
            sx={{ marginBottom: 2 }}
          >
            {buses.map((bus) => (
              <MenuItem key={bus.busId} value={bus.busRegistration}>
              {bus.busRegistration	}
            </MenuItem>
            
            ))}
          </TextField>

          <TextField
            select
            label="Select Driver"
            fullWidth
            value={selectedDriver}
            onChange={(e) => setSelectedDriver(e.target.value)}
            sx={{ marginBottom: 2 }}
          >
            {drivers.map((driver) => (
              <MenuItem key={driver.id} value={`${driver.firstName} ${driver.lastName}`}>
                {`${driver.firstName} ${driver.lastName}`}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            select
            label="Select Conductor"
            fullWidth
            value={selectedConductor}
            onChange={(e) => setSelectedConductor(e.target.value)}
          >
            {conductors.map((conductor) => (
              <MenuItem key={conductor.id} value={`${conductor.firstName} ${conductor.lastName}`}>
                {`${conductor.firstName} ${conductor.lastName}`}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            select
            label="Select Origin"
            fullWidth
            value={selectedLocation}
            onChange={(e) => setSelectedLocation(e.target.value)}
            sx={{ marginBottom: 2 }}
          >
            {locations.map((location) => (
              <MenuItem key={location} value={location}>
                {location}
              </MenuItem>
            ))}
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAssignBus} color="primary">
            Assign & Close
          </Button>
          <Button onClick={closeAssignDialog} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>


        <Typography 
          variant="h6" 
          gutterBottom 
          sx={{ 
            color: '#333', 
            fontWeight: '600' 
          }}
        >
          Select a Date and Route to View Passengers
        </Typography>

        <Grid 
          container 
          spacing={2} 
          sx={{ 
            alignItems: 'center' 
          }}
        >
          <Grid item xs={12} sm={4}>
            <TextField
              label="Select Date"
              type="date"
              fullWidth
              value={selectedDate}
              onChange={(e) => setSelectedDate(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                '& .MuiInputBase-root': {
                  borderRadius: '4px',
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#1976d2',
                  },
                  '&:hover fieldset': {
                    borderColor: '#115293',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#0d47a1',
                  },
                },
              }}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              select
              label="Select Route"
              fullWidth
              value={selectedDirection}
              onChange={(e) => setSelectedDirection(e.target.value)}
              sx={{
                '& .MuiInputBase-root': {
                  borderRadius: '4px',
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#1976d2',
                  },
                  '&:hover fieldset': {
                    borderColor: '#115293',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#0d47a1',
                  },
                },
              }}
            >
              <MenuItem value={1}>Nairobi → Wajir</MenuItem>
              <MenuItem value={2}>Wajir → Nairobi</MenuItem>
            </TextField>
          </Grid>
        
          <Grid item xs={12} sm={4}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={fetchBookedSeats}
              sx={{
                height: '56px',
                backgroundColor: '#1976d2',
                '&:hover': {
                  backgroundColor: '#0d47a1',
                },
              }}
            >
              View Passengers
            </Button>
          </Grid>
        </Grid>
      </Box>

      {error && (
        <Typography 
          variant="body1" 
          color="error" 
          sx={{ 
            marginBottom: 3 
          }}
        >
          {error}
        </Typography>
      )}

      <DataTable
        title="Passenger List"
        columns={columns}
        data={bookedSeats}
        customStyles={customStyles}
        pagination
      />

      <Box 
        sx={{ 
          textAlign: 'center', 
          marginTop: 4 
        }}
      >
        <Button 
          variant="contained" 
          color="primary" 
          onClick={downloadPDF}
          sx={{
            padding: '10px 20px',
            backgroundColor: '#1976d2',
            '&:hover': {
              backgroundColor: '#0d47a1',
            },
          }}
        >
          Download PDF
        </Button>
      </Box>
    </Box>
  );
};

export default UserManifest;
